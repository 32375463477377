<template>
  <div class="wrapper" id="instructions_wrapper">
    <img class="bg" :src="require('./instructions.png')" alt="" />
  </div>
</template>

<script>
export default {
  data: {
  },
  mounted () {
    const height = document.body.clientHeight
    const wrapperRef = document.getElementById('instructions_wrapper')
    wrapperRef.style.height = height + 'px'
  },
  methods: {
    handleLogOff () {

    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: center;
  width: 100%;
  background-color: rgb(52, 123, 232);
  .bg {
    width: 100%;
  }
}
</style>